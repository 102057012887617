import api from "@/assets/js/api";

export const GET_GROUP_CATEGORIES = "group/actions/get-categories";
export const CREATE_GROUP = "group/actions/create";
export const UPDATE_GROUP = "group/actions/update";
export const DELETE_GROUP = "group/actions/delete";
export const GET_GROUPS = "group/actions/get";
export const ADD_GROUP_MEMBER = "group/actions/add-member";
export const DELETE_GROUP_MEMBER = "group/actions/delete-member";

const groupModule = {
    actions: {
        async [GET_GROUPS](
            _,
            { page, perPage, sortBy, groupType, groupSize, dateFrom, dateTo }
        ) {
            try {
                const route = `${api.baseURL}/api/groups`;
                const url = new URL(route);
                const params = new URLSearchParams(url.search);
                params.set("page", page);
                params.set("per_page", perPage || 10);
                if (sortBy) params.set("sort_by", sortBy);
                if (groupType) params.set("group_type", groupType);
                if (groupSize) params.set("group_size", groupSize);
                if (dateFrom && dateTo) {
                    params.set("from", dateFrom);
                    params.set("to", dateTo);
                }
                const response = await api.main.get({
                    url: `/api/groups?${params}`,
                });
                const data = response.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [GET_GROUP_CATEGORIES](_, { search, page, perPage, sortBy }) {
            try {
                const route = `${api.baseURL}/api/group-categories`;
                const url = new URL(route);
                const params = new URLSearchParams(url.search);
                params.set("page", page);
                params.set("per_page", perPage);
                if (search) params.set("search", search);
                if (sortBy) params.set("sort_by", sortBy);
                const response = await api.main.get({
                    url: `/api/group-categories?${params}`,
                });
                const data = response.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [CREATE_GROUP](_, payload) {
            try {
                const response = await api.main.post({
                    url: "/api/groups",
                    data: Object.assign({}, payload),
                });
                const data = response.data.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [ADD_GROUP_MEMBER](_, payload) {
            try {
                const groupID = payload.groupID;
                delete payload.groupID;
                const response = await api.main.post({
                    url: `/api/group-members-management/${groupID}`,
                    data: Object.assign({}, payload),
                });
                const data = response.data.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [UPDATE_GROUP](_, payload) {
            try {
                payload._method = "PUT";
                const groupID = payload.groupID;
                delete payload.groupID;
                const response = await api.main.post({
                    url: `/api/groups/${groupID}`,
                    data: Object.assign({}, payload),
                });
                const data = response.data.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [DELETE_GROUP_MEMBER](_, payload) {
            try {
                const groupID = payload.groupID;
                delete payload.groupID;
                console.log(payload);
                const response = await api.main.delete({
                    url: `/api/group-members-management/${groupID}`,
                    data: Object.assign({}, payload),
                });
                const data = response.data.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [DELETE_GROUP](_, groupID) {
            try {
                const response = await api.main.delete({
                    url: `/api/groups/${groupID}`,
                });
                const data = response.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },
    },
};

export default groupModule;
