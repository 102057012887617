import api from "@/assets/js/api";

const SET_LEARN_COLLECTIONS_DATA = "selection/mutation/set-learn-collections";
export const GET_LEARN_COLLECTIONS = "selection/actions/get/learn-collections";
const SET_PLAYLISTS_DATA = "selection/mutation/set-playlists";
export const GET_PLAYLISTS = "selection/actions/get/playlists";
const SET_LANGUAGES_DATA = "selection/mutation/set-languages";
export const GET_LANGUAGES = "selection/actions/get/languages";
const SET_VIDEOS_DATA = "selection/mutation/set-videos";
export const GET_VIDEOS = "selection/actions/get/videos";
const SET_LEARN_TAGS_DATA = "selection/mutation/set-learn-tags";
export const GET_LEARN_TAGS = "selection/actions/get/learn-tags";
const SET_GG_LEADERS_DATA = "selection/mutation/set-gg-leaders";
export const GET_GG_LEADERS = "selection/actions/get/gg-leaders";
const SET_GROUPS_DATA = "selection/mutation/set-groups";
export const GET_GROUPS = "selection/actions/get/groups";
const SET_BIBLE_BOOKS_DATA = "selection/mutation/set-books";
export const GET_BIBLE_BOOKS = "selection/actions/get/books";

const sorts = [
    {
        text: "Ascending",
        value: "asc",
    },
    {
        text: "Descending",
        value: "desc",
    },
];

const perPageItems = [10, 20, 30, 40, 50];

const prefixes = ["Mr", "Ms", "Mrs", "Miss"];

const genders = ["Male", "Female"];

const maritalStatuses = [
    "Single",
    "Married",
    "Separated",
    "Annulled",
    "Divorced",
    "Widowed",
];

const selectionModule = {
    state: {
        learnCollections: [],
        playlists: [],
        languages: [],
        videos: [],
        sorts,
        perPageItems,
        learnTags: [],
        ggLeaders: [],
        prefixes,
        genders,
        maritalStatuses,
        groups: [],
        bibleBooks: [],
    },

    mutations: {
        [SET_LEARN_COLLECTIONS_DATA](state, data) {
            state.learnCollections = data;
        },

        [SET_PLAYLISTS_DATA](state, data) {
            state.playlists = data;
        },

        [SET_LANGUAGES_DATA](state, data) {
            state.languages = data;
        },

        [SET_VIDEOS_DATA](state, data) {
            state.videos = data;
        },

        [SET_LEARN_TAGS_DATA](state, data) {
            state.learnTags = data;
        },

        [SET_GG_LEADERS_DATA](state, data) {
            state.ggLeaders = data;
        },

        [SET_GG_LEADERS_DATA](state, data) {
            state.ggLeaders = data;
        },

        [SET_GROUPS_DATA](state, data) {
            state.groups = data;
        },

        [SET_BIBLE_BOOKS_DATA](state, data) {
            state.bibleBooks = data;
        },
    },

    actions: {
        async [GET_LEARN_COLLECTIONS]({ commit }) {
            try {
                const response = await api.main.get({
                    url: "/api/select/learn-collections",
                });
                const data = response.data.data;
                commit(SET_LEARN_COLLECTIONS_DATA, data);
                return api.formatResponse(true, data);
            } catch (error) {
                commit(SET_LEARN_COLLECTIONS_DATA, []);
                return api.formatResponse(false, []);
            }
        },

        async [GET_PLAYLISTS]({ commit }) {
            try {
                const response = await api.main.get({
                    url: "/api/select/playlists",
                });
                const data = response.data.data;
                commit(SET_PLAYLISTS_DATA, data);
                return api.formatResponse(true, data);
            } catch (error) {
                commit(SET_PLAYLISTS_DATA, []);
                return api.formatResponse(false, []);
            }
        },

        async [GET_LANGUAGES]({ commit }) {
            try {
                const response = await api.main.get({
                    url: "/api/select/languages",
                });
                const data = response.data.data;
                commit(SET_LANGUAGES_DATA, data);
                return api.formatResponse(true, data);
            } catch (error) {
                commit(SET_LANGUAGES_DATA, []);
                return api.formatResponse(false, []);
            }
        },

        async [GET_VIDEOS]({ commit }) {
            try {
                console.log("GETTING VIDEOS");
                const response = await api.main.get({
                    url: "/api/select/videos",
                });
                const data = response.data.data;
                commit(SET_VIDEOS_DATA, data);
                return api.formatResponse(true, data);
            } catch (error) {
                commit(SET_VIDEOS_DATA, []);
                return api.formatResponse(false, []);
            }
        },

        async [GET_LEARN_TAGS]({ commit }) {
            try {
                const response = await api.main.get({
                    url: "/api/select/learn-tags",
                });
                const data = response.data.data;
                commit(SET_LEARN_TAGS_DATA, data);
                return api.formatResponse(true, data);
            } catch (error) {
                commit(SET_LEARN_TAGS_DATA, []);
                return api.formatResponse(false, []);
            }
        },

        async [GET_GG_LEADERS]({ commit }, { search, page, perPage, sortBy }) {
            try {
                const route = `${api.baseURL}/api/select/gg-leaders`;
                const url = new URL(route);
                const params = new URLSearchParams(url.search);
                params.set("page", page);
                params.set("per_page", perPage);
                if (search) params.set("search", search);
                if (sortBy) params.set("sort_by", sortBy);
                const response = await api.main.get({
                    url: `/api/select/gg-leaders?${params}`,
                });
                const data = response.data;
                commit(SET_GG_LEADERS_DATA, data.data);
                return api.formatResponse(true, data);
            } catch (error) {
                commit(SET_GG_LEADERS_DATA, []);
                return api.formatResponse(false, []);
            }
        },

        async [GET_GROUPS]({ commit }) {
            try {
                const response = await api.main.get({
                    url: "/api/select/groups",
                });
                const data = response.data.data;
                commit(SET_GROUPS_DATA, data);
                return api.formatResponse(true, data);
            } catch (error) {
                commit(SET_GROUPS_DATA, []);
                return api.formatResponse(false, []);
            }
        },

        async [GET_BIBLE_BOOKS]({ commit }) {
            try {
                const response = await api.main.get({
                    url: "/api/select/bible-books",
                });
                const data = response.data.data;
                commit(SET_BIBLE_BOOKS_DATA, data);
                return api.formatResponse(true, data);
            } catch (error) {
                commit(SET_BIBLE_BOOKS_DATA, []);
                return api.formatResponse(false, []);
            }
        },
    },
};

export default selectionModule;
