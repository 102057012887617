import api from "@/assets/js/api";

export const CREATE_DAILY_DEVOTION = "daily-devotion/actions/create";
export const UPDATE_DAILY_DEVOTION = "daily-devotion/actions/update";
export const GET_DAILY_DEVOTIONS = "daily-devotion/actions/get";
export const DELETE_DAILY_DEVOTION = "daily-devotion/actions/delete";

const toFormData = (
    {
        bible_book_id,
        title,
        bible_verse,
        bible_url,
        schedule_at,
        devotion_image,
    },
    operation
) => {
    const formData = new FormData(); 
    if (bible_book_id) {
        formData.set("bible_book_id", bible_book_id);
    }
    if (title) {
        formData.set("title", title);
    }
    if (bible_verse) {
        formData.set("bible_verse", bible_verse);
    }
    if (bible_url) {
        formData.set("bible_url", bible_url);
    }
    if (schedule_at) {
        formData.set("schedule_at", schedule_at);
    }
    if (devotion_image) {
        formData.set("devotion_image", devotion_image);
    }
    if (operation === "update") formData.set("_method", "PUT");

    return formData;
};

const dailyDevotionModule = {
    actions: {
        async [CREATE_DAILY_DEVOTION](_, payload) {
            try {
                const formData = toFormData(payload);
                const response = await api.main.post({
                    url: "/api/daily-devotions",
                    data: formData,
                });
                const data = response.data.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [UPDATE_DAILY_DEVOTION](_, payload) {
            try {
                const formData = toFormData(payload, "update");
                const dailyDevotionID = payload.dailyDevotionID;
                const response = await api.main.post({
                    url: `/api/daily-devotions/${dailyDevotionID}`,
                    data: formData,
                });
                const data = response.data.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [GET_DAILY_DEVOTIONS](
            _,
            { page, perPage, sortBySchedule, from, to, book }
        ) {
            try {
                const route = `${api.baseURL}/api/daily-devotions`;
                const url = new URL(route);
                const params = new URLSearchParams(url.search);
                params.set("page", page);
                if (perPage) {
                    params.set("per_page", perPage);
                }
                if (sortBySchedule) {
                    params.set("sort_by_schedule", sortBySchedule);
                }
                if (from && to) {
                    params.set("from", from);
                    params.set("to", to);
                }
                if (book) {
                    params.set("book", book);
                }

                const response = await api.main.get({
                    url: `/api/daily-devotions?${params}`,
                });
                const data = response.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [DELETE_DAILY_DEVOTION](_, dailyDevotionID) {
            try {
                const response = await api.main.delete({
                    url: `/api/daily-devotions/${dailyDevotionID}`,
                });
                const data = response.data.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },
    },
};

export default dailyDevotionModule;
