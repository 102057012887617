'use strict';

import users from '@/assets/js/api/modules/users';

export default {
  namespaced: true,
  state: {
    requests_list: undefined,
    request_details: undefined
  },
  getters: {
    getRequestsList: state => state.requests_list,
    getRequestDetails: state => state.request_details,
  },
  mutations: {
    SET_REQUESTS_LIST(state, payload) {
        state.requests_list = payload;
    },
    SET_REQUEST_DETAILS(state, payload) {
        state.request_details = payload;
    },
    CLEAR_STATE(state) {
        state.requests_list = undefined;
        state.request_details = undefined;
    }
  },
  actions: {
    fetchRequests(context, payload) {
      const { config } = payload || {};
      return new Promise((resolve, reject) => {
        users.getData({ to: '/api/prayer-requests', config }).then(
          (response) => {
            // console.log(response);
            context.commit('SET_REQUESTS_LIST', response);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.commit('SET_REQUESTS_LIST', undefined);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    findRequest(context, payload) {
      const { id, config } = payload || {};
      return new Promise((resolve, reject) => {
        users.getData({ to: `/api/requests/${id}`, config }).then(
          (response) => {
            // console.log(response);
            context.commit('SET_REQUEST_DETAILS', response.data);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.commit('SET_REQUEST_DETAILS', undefined);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    fetchRequestTypes(context, payload) {
      const { config } = payload || {};
      return new Promise((resolve, reject) => {
        users.getData({ to: '/api/request-types', config }).then(
          (response) => {
            // console.log(response);
            context.commit('SET_REQUEST_TYPES', response.data);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.commit('SET_REQUESTS_TYPES', undefined);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    addRequest(context, payload) {
      const { data, config } = payload || {};
      return new Promise((resolve, reject) => {
        users.postData({ to: '/api/requests', data, config }).then(
          (response) => {
            // console.log(response);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    setRequest(context, payload) {
      const { id, data, config } = payload || {};
      return new Promise((resolve, reject) => {
        users.putData({ to: `/api/requests/${id}`, data, config }).then(
          (response) => {
            // console.log(response);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    deleteUser(context, payload) {
      const { config } = payload || {};
      return new Promise((resolve, reject) => {
        users.deleteData({ to: '/api/users', config }).then(
          (response) => {
            // console.log(response);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    }
  }
};