import api from "@/assets/js/api";

export const CREATE_EVENT = "event/actions/create";
export const UPDATE_EVENT = "event/actions/update";
export const GET_EVENTS = "event/actions/get";
export const DELETE_EVENT = "event/actions/delete";

const toFormData = (
    {
        image,
        event_name,
        description,
        is_public,
        location,
        is_allday,
        event_start,
        event_end,
    },
    operation
) => {
    const formData = new FormData();
    if (image) {
        formData.set("image", image);
    }
    if (event_name) {
        formData.set("event_name", event_name);
    }
    if (description) {
        formData.set("description", description);
    }
    if (location) {
        formData.set("location", location);
    }
    if (event_start) {
        formData.set("event_start", event_start);
    }
    if (event_end) {
        formData.set("event_end", event_end);
    }
    formData.set("is_allday", is_allday ? "1" : "0");
    formData.set("is_public", is_public);
    if (operation === "update") formData.set("_method", "PUT");

    return formData;
};

const watchModule = {
    actions: {
        async [CREATE_EVENT](_, payload) {
            try {
                const formData = toFormData(payload);
                const response = await api.main.post({
                    url: "/api/events",
                    data: formData,
                });
                const data = response.data.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [UPDATE_EVENT](_, payload) {
            try {
                const formData = toFormData(payload, "update");
                const eventID = payload.eventID;
                const response = await api.main.post({
                    url: `/api/events/${eventID}`,
                    data: formData,
                });
                const data = response.data.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [GET_EVENTS](_, { page, perPage, sortBy, from, to }) {
            try {
                const route = `${api.baseURL}/api/events`;
                const url = new URL(route);
                const params = new URLSearchParams(url.search);
                params.set("page", page);
                if (perPage) {
                    params.set("per_page", perPage);
                }
                if (sortBy) {
                    params.set("sort_by", sortBy);
                }
                if (from && to) {
                    params.set("from", from);
                    params.set("to", to);
                }

                const response = await api.main.get({
                    url: `/api/events?${params}`,
                });
                const data = response.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [DELETE_EVENT](_, eventID) {
            try {
                const response = await api.main.delete({
                    url: `/api/events/${eventID}`,
                });
                const data = response.data.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },
    },
};

export default watchModule;
