import api from "@/assets/js/api";
export const GET_USER_REPORT_ANALYTICS = "report/get-user-analytics";
export const GET_AGE_REPORT_DEMOGRAPHICS = "report/get-age-demographics";
export const GET_GENDER_REPORT_DEMOGRAPHICS = "report/get-gender-demographics";
export const GET_MEMBERSHIP_REPORT_DEMOGRAPHICS =
    "report/get-membership-demographics";
export const GET_TOTAL_VIDEO_VIEWS = "report/get-total-video-views";
export const GET_TOTAL_READ = "report/get-total-read";

const reportModule = {
    actions: {
        async [GET_USER_REPORT_ANALYTICS]() {
            try {
                const route = `${api.baseURL}/api/user-analytics`;
                const url = new URL(route);
                const params = new URLSearchParams(url.search);

                const response = await api.main.get({
                    url: `/api/user-analytics?${params}`,
                });
                const data = response.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [GET_GENDER_REPORT_DEMOGRAPHICS](_, payload) {
            try {
                const route = `${api.baseURL}/api/user-demographics/gender`;
                const url = new URL(route);
                const params = new URLSearchParams(url.search);
                Object.entries(payload).forEach((item) =>
                    params.set(item[0], item[1])
                );
                const response = await api.main.get({
                    url: `/api/user-demographics/gender?${params}`,
                });
                const data = response.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [GET_AGE_REPORT_DEMOGRAPHICS](_, payload) {
            try {
                const route = `${api.baseURL}/api/user-demographics/age`;
                const url = new URL(route);
                const params = new URLSearchParams(url.search);
                Object.entries(payload).forEach((item) =>
                    params.set(item[0], item[1])
                );

                const response = await api.main.get({
                    url: `/api/user-demographics/age?${params}`,
                });
                const data = response.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [GET_MEMBERSHIP_REPORT_DEMOGRAPHICS](_, payload) {
            try {
                const route = `${api.baseURL}/api/user-demographics/membership`;
                const url = new URL(route);
                const params = new URLSearchParams(url.search);
                Object.entries(payload).forEach((item) =>
                    params.set(item[0], item[1])
                );

                const response = await api.main.get({
                    url: `/api/user-demographics/membership?${params}`,
                });
                const data = response.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },
        async [GET_TOTAL_VIDEO_VIEWS](_, payload) {
            try {
                const route = `${api.baseURL}/api/app-usage/total-video-views`;
                const url = new URL(route);
                const params = new URLSearchParams(url.search);
                Object.entries(payload).forEach((item) =>
                    params.set(item[0], item[1])
                );
                const response = await api.main.get({
                    url: `/api/app-usage/total-video-views?${params}`,
                });
                const data = response.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },
        async [GET_TOTAL_READ](_, payload) {
            try {
                const route = `${api.baseURL}/api/app-usage/total-read-contents`;
                const url = new URL(route);
                const params = new URLSearchParams(url.search);
                Object.entries(payload).forEach((item) =>
                    params.set(item[0], item[1])
                );
                const response = await api.main.get({
                    url: `/api/app-usage/total-read-contents?${params}`,
                });
                const data = response.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },
    },
};

export default reportModule;
