import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: "#369776",
                primaryBackgroundColor: "#FFFFFF",
                secondaryBackgroundColor: "#F1EEEE",
                error: "#FF726F",
                primaryTextColor: "#369776",
                success: "#389877",
                secondary: "#78756A",
                accent: "#2C423D",
            },
            dark: {
                primary: "#376BFA",
                primaryTextColor: "#FFFFFF",
                primaryBackgroundColor: "#323B5F",
                secondaryBackgroundColor: "#212746",
                error: "#FF726F",
            },
        },
    },
});
