<template>
  <v-app color="contentBackgroundColor">
    <router-view class="main-content" />
  </v-app>
</template>

<script>
  export default {
    name: 'app',
    data() {
      return {};
    },
    computed: {
      // token() { return this.$store.getters['authentication/getToken']; }
    },
    watch: {
      // token(to, from) {
      //   console.log(to, from);
      //   // if (!to) {}
      // }
    },
    methods: {
      initiate() {
        this.events();
        this.checkToken();
      },
      events() {
        this.$eventBus.$on('clear-data', this.clearData);
      },
      checkToken() {
        const token = this.$cookies.getValue('token');
        if (token) { this.$store.dispatch('authentication/setToken', token); }
      },
      clearData() {
        this.$store.dispatch('clearState');
        this.$cookies.removeValue('token');
        this.$cookies.removeValue('user');
        this.$api.main.setAuthorization(undefined);
        this.$router.push({ name: 'login', query: { redirect: ((this.$route.meta.requiresAuthentication) ? this.$route.name : undefined) } });
      }
    },
    created() {
      this.initiate();
      // Detect System Dark Mode
      // if (!!window && !!window.matchMedia) {
      //   this.$vuetify.theme.dark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      // }
    },
    beforeDestroy() {
      this.$eventBus.$off('clear-data');
    }
  };
</script>

<style lang="scss">
  @import '@/assets/css/main.scss';
  @import url('https://fonts.googleapis.com/css?family=Material+Icons');
</style>