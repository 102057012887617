"use strict";

import Axios from "@/plugins/axios";

// Process ENV Details
// console.log(process.env);

const formatResponse = (success, data, error) => {
    return {
        success: !!success,
        data,
        message: error ? error.response.data.message : null,
    };
};

const baseURL = `${process.env.VUE_APP_BASE_API}/api`;

const api = {
    main: Axios({ baseURL: process.env.VUE_APP_BASE_API }),
    formatResponse,
    baseURL,
};

export default api;
