import api from "@/assets/js/api";

export const LEARN_CREATE_MATERIAL = "learn/actions/create/material";
export const LEARN_GET_MATERIALS = "watch/actions/get/materials";
export const LEARN_UPDATE_MATERIAL = "learn/actions/update/material";
export const LEARN_DELETE_MATERIAL = "learn/actions/delete/material";

const toFormData = (
    {
        learn_collection_id,
        title,
        sub_title,
        description,
        thumbnail,
        tags,
        pdf_url,
        life_journey_subjects,
        video_relates,
    },
    operation
) => {
    const formData = new FormData();
    if (learn_collection_id)
        formData.set("learn_collection_id", learn_collection_id);
    if (title) formData.set("title", title);
    if (sub_title) formData.set("sub_title", sub_title);
    if (description) formData.set("description", description);
    if (thumbnail) formData.set("thumbnail", thumbnail);
    if (pdf_url) formData.set("pdf_url", pdf_url);
    tags.forEach((tag, index) => {
        if (typeof tag === "object") {
            formData.append(`tags[${index}][id]`, tag.id);
            formData.append(`tags[${index}][name]`, tag.name);
        } else if (typeof tag === "string") {
            formData.append(`tags[${index}][name]`, tag);
        }
    });
    const isSermon = learn_collection_id && learn_collection_id === 2;
    const isLifeJourney = learn_collection_id && learn_collection_id === 1;
    if (isSermon) {
        video_relates.forEach((video, index) => {
            formData.append(`video_relates[${index}][video_id]`, video);
        });
    }
    if (isLifeJourney) {
        life_journey_subjects.forEach((subject, subjectIndex) => {
            if (subject.id) {
                formData.append(
                    `life_journey_subjects[${subjectIndex}][id]`,
                    subject.id
                );
            }
            if (subject.title) {
                formData.append(
                    `life_journey_subjects[${subjectIndex}][title]`,
                    subject.title
                );
            }
            if (subject.description) {
                formData.append(
                    `life_journey_subjects[${subjectIndex}][description]`,
                    subject.description
                );
            }
            if (subject.thumbnail) {
                formData.append(
                    `life_journey_subjects[${subjectIndex}][thumbnail]`,
                    subject.thumbnail
                );
            }
            subject.files.forEach((file, fileIndex) => {
                if (file.id) {
                    formData.append(
                        `life_journey_subjects[${subjectIndex}][files][${fileIndex}][id]`,
                        file.id
                    );
                }
                if (file.description) {
                    formData.append(
                        `life_journey_subjects[${subjectIndex}][files][${fileIndex}][description]`,
                        file.description
                    );
                }
                if (file.file) {
                    formData.append(
                        `life_journey_subjects[${subjectIndex}][files][${fileIndex}][file]`,
                        file.file
                    );
                    file.pdf_url = null; // this assignment is to ensure the pdf_url have no value if there is uploaded file.
                }
                if (file.pdf_url) {
                    formData.append(
                        `life_journey_subjects[${subjectIndex}][files][${fileIndex}][pdf_url]`,
                        file.pdf_url || null
                    );
                }
                if (file.name) {
                    formData.append(
                        `life_journey_subjects[${subjectIndex}][files][${fileIndex}][name]`,
                        file.name || null
                    );
                }
                formData.append(
                    `life_journey_subjects[${subjectIndex}][files][${fileIndex}][pages]`,
                    file.pages || 0
                );
                formData.append(
                    `life_journey_subjects[${subjectIndex}][files][${fileIndex}][hour_to_read]`,
                    file.hour_to_read || 0
                );
            });
        });
    }
    if (operation === "update") formData.set("_method", "PUT");

    return formData;
};

const learnModule = {
    actions: {
        async [LEARN_CREATE_MATERIAL](_, payload) {
            try {
                const formData = toFormData(payload);
                const response = await api.main.post({
                    url: "/api/learns",
                    data: formData,
                });
                const data = response.data.data;
                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [LEARN_GET_MATERIALS](
            _,
            {
                page,
                perPage,
                uploadSort,
                learnCollection,
                tags,
                dateFrom,
                dateTo,
            }
        ) {
            try {
                const route = `${api.baseURL}/api/learns`;
                const url = new URL(route);
                const params = new URLSearchParams(url.search);
                params.set("page", page);
                params.set("per_page", perPage);
                params.set("sort_by", uploadSort);
                if (learnCollection) {
                    learnCollection.forEach((item, index) =>
                        params.append(`learn_collection[${index}]`, item)
                    );
                }
                if (tags) {
                    tags.forEach((item, index) =>
                        params.append(`tags[${index}]`, item)
                    );
                }
                if (dateFrom) {
                    params.set("from", dateFrom);
                }
                if (dateTo) {
                    params.set("to", dateTo);
                }
                const response = await api.main.get({
                    url: `/api/learns?${params}`,
                });
                const data = response.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [LEARN_UPDATE_MATERIAL](_, payload) {
            try {
                const formData = toFormData(payload, "update");
                const materialID = payload.id;
                const response = await api.main.post({
                    url: `/api/learns/${materialID}`,
                    data: formData,
                });
                const data = response.data.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [LEARN_DELETE_MATERIAL](_, materialID) {
            try {
                const response = await api.main.delete({
                    url: `/api/learns/${materialID}`,
                });
                const data = response.data.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },
    },
};

export default learnModule;
