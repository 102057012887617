import api from "@/assets/js/api";

export const GET_SETTINGS_ACCESS_CONTROL = "settings/get/access-control";
export const UPDATE_ACCESS_CONTROL = "settings/update/access-control";

const settingsModule = {
    actions: {
        async [GET_SETTINGS_ACCESS_CONTROL](_, userID) {
            try {
                const response = await api.main.get({
                    url: `/api/access-control/${userID}`,
                });
                const data = response.data;
                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, []);
            }
        },

        async [UPDATE_ACCESS_CONTROL](_, payload) {
            try {
                const userID = payload.id;
                delete payload.id;
                const response = await api.main.post({
                    url: `/api/access-control/${userID}`,
                    data: Object.assign({}, payload),
                });
                const data = response.data.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },
    },
};

export default settingsModule;
