import api from "@/assets/js/api";

export const GET_SESSIONS = "session/get";
export const GET_SESSION = "session/get-one";
export const CREATE_SESSION = "session/create";
export const UPDATE_SESSION = "session/update";
export const DELETE_SESSION = "session/delete";

const sessionModule = {
    actions: {
        async [GET_SESSIONS](
            _,
            { page, perPage, search, sortBy, group, dateFrom, dateTo }
        ) {
            try {
                const route = `${api.baseURL}/api/sessions`;
                const url = new URL(route);
                const params = new URLSearchParams(url.search);
                params.set("page", page);
                params.set("per_page", perPage || 10);
                if (sortBy) params.set("sort_by", sortBy);
                if (search) params.set("search", search);
                if (group) params.set("group", group);
                if (dateFrom && dateTo) {
                    params.set("from", dateFrom);
                    params.set("to", dateTo);
                }
                const response = await api.main.get({
                    url: `/api/sessions?${params}`,
                });
                const data = response.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [GET_SESSION](_, sessionID) {
            try {
                const response = await api.main.get({
                    url: `/api/sessions/${sessionID}`,
                });
                const data = response.data.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [CREATE_SESSION](_, payload) {
            try {
                const response = await api.main.post({
                    url: "/api/sessions",
                    data: Object.assign({}, payload),
                });
                const data = response.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [UPDATE_SESSION](_, payload) {
            try {
                payload._method = "PUT";
                const sessionID = payload.sessionID;
                delete payload.sessionID;
                const response = await api.main.post({
                    url: `/api/sessions/${sessionID}`,
                    data: Object.assign({}, payload),
                });
                const data = response.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [DELETE_SESSION](_, sessionID) {
            try {
                const response = await api.main.delete({
                    url: `/api/sessions/${sessionID}`,
                });
                const data = response.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },
    },
};

export default sessionModule;
